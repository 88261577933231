import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";
import { cardMixin } from "@/widgets/Container/Templates/mixin";
export const NewsSkeletonContainer = styled.div`
  height: 100%;
  width: 100%;

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    right: -11px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #e0e0e0;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        background-color: ${ColorDark.greySeparator};
      }
    }
    ${breakpoints.phone} {
      display: none;
    }
  }

  ${breakpoints.phone} {
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-bottom: 20px !important;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .picture {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 3.5s infinite;
    height: 100%;
    width: 100%;
    ${breakpoints.phone} {
      min-height: 230px;
      height: auto;
      aspect-ratio: 1 / 1;
      max-height: 930px;
    }
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        background: linear-gradient(
          90deg,
          #a0a0a0 25%,
          #808080 50%,
          #a0a0a0 75%
        );
      }
    }
  }

  .mt {
    display: none;
    ${breakpoints.phone} {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      width: 100%;

      .volanta,
      .title,
      .data-txt {
        margin-top: 10px;
        border-radius: 14px;
        height: 18px;
        background: linear-gradient(
          90deg,
          #f0f0f0 25%,
          #e0e0e0 50%,
          #f0f0f0 75%
        );
        background-size: 200% 100%;
        animation: shimmer 3.5s infinite;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            background: linear-gradient(
              90deg,
              #a0a0a0 25%,
              #808080 50%,
              #a0a0a0 75%
            );
          }
        }
      }
      .volanta {
        width: 50%;
      }
      .title {
        width: 90%;
        height: 24px;
      }
      .data-txt {
        width: 80%;
      }
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`;
