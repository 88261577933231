import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";
import { cardMixin } from "@/widgets/Container/Templates/mixin";
export const NewsSkeletonContainer = styled.div`
  ${cardMixin}
  position: relative;
  column-gap: 1rem;
  width: 100%;
  padding-bottom: 0px;
  height: 315px;
  display: flex;
  flex-direction: column;

  &:not(:last-child)::before {
    right: -10px;
  }
  ${breakpoints.tablet} {
    ::before {
      display: none;
    }
  }
  ${breakpoints.phone} {
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-bottom: 20px !important;
    height: auto;
  }

  .picture,
  .volanta,
  .title,
  .data-txt {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 3.5s infinite;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        background: linear-gradient(
          90deg,
          #a0a0a0 25%,
          #808080 50%,
          #a0a0a0 75%
        );
      }
    }
  }
  .picture {
    height: 148px;
    width: 100%;
    ${breakpoints.phone} {
      min-height: 170px;
      height: auto;
      aspect-ratio: 16 / 9;
      max-height: 665px;
    }
  }
  .mt {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .volanta,
    .title,
    .data-txt {
      margin-top: 10px;
      border-radius: 14px;
      height: 18px;
    }
    .volanta {
      width: 50%;
    }
    .title {
      width: 90%;
      height: 24px;
    }
    .data-txt {
      width: 80%;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`;
