import { NewsSkeletonContainer } from "./styles";

export const GenericNormalSkeleton = ({ classType }) => {
  return (
    <NewsSkeletonContainer className={`${classType} normal-skeleton`}>
      <div className="picture"></div>
      <div className="mt">
        <div className="volanta"></div>
        <div className="title"></div>
        <div className="data-txt"></div>
      </div>
    </NewsSkeletonContainer>
  );
};
